
export const feeType = [{
  text: '03',
  value: '提现'
},
{
  text: '04',
  value: '充值'
},
{
  text: 'SMS',
  value: '短信充值'
},
{
  text: 'INSURANCE',
  value: '保险充值'
},
{
  text: 'AUTOCFRT02',
  value: '自动到账'
},
{
  text: 'DF',
  value: '垫付'
},
{
  text: 'GOODSPRICE',
  value: '代收款'
},
{
  text: 'HK',
  value: '回款'
},
{
  text: 'INFOFEE',
  value: '信息费'
},
{
  text: 'INFOFEE_AUTO_CFRA01',
  value: '信息费'
},
{
  text: 'LogKHuiFu',
  value: '收款'
},
{
  text: 'LogKTiFu',
  value: '收款'
},
{
  text: 'LogKXianFu',
  value: '收款'
},
{
  text: 'LogMPTiFu',
  value: '收款'
},
{
  text: 'OLScanPay',
  value: '充值'
},
{
  text: 'REFUND',
  value: '退款'
},
{
  text: 'REFUNDFEE',
  value: '返款费'
},
{
  text: 'SERVICEFEE',
  value: '手续费'
},
{
  text: 'StaticQRCode',
  value: '静态码收款'
},
{
  text: 'TRANSFERFEE1',
  value: '车费'
},
{
  text: 'TRANSFERFEE2',
  value: '车费'
},
{
  text: 'TRANSFERFEE3',
  value: '车费'
},
{
  text: 'TRANSFERINFEE',
  value: '返款费'
},
{
  text: 'TRANSIT2FEE',
  value: '中转支出'
},
{
  text: 'TRANSPORT2FEE',
  value: '车费'
},
{
  text: 'TRANSPORT3FEE',
  value: '车费'
},
{
  text: 'ZZ',
  value: '转账'
}
]
