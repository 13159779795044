// import instance from '@/utils/request'

// export const getLogin = (params = {}) => {
//   return instance({
//     method: 'GET',
//     url: '/api/login/login',
//     params
//   })
// }
import http from './index.js'
import { https } from './config.js'

/**
 *  @param '/api'代表config中index.js中配置的代理
 */
// 结算列表
export function jlist (data) {
  return http.httpRequest(https.baseUrl + '/j-order/search', 'POST', {}, data, true)
}

// 扫码支付列表
export function abcList (data) {
  return http.httpRequest(https.baseUrl + '/abc/merchant-pay-list', 'POST', {}, data, true)
}
// 登录
export function login (data) {
  return http.httpRequest(https.baseUrl + '/third-auth/abc', 'POST', {}, data, false)
}
// 请求验证码
export function sms (data) {
  return http.httpRequest(https.baseUrl + '/third-auth/abc/sms', 'POST', {}, data, false)
}
export function abcduizhang (data) {
  return http.httpRequest(https.baseUrl + '/abc/adapter', 'POST', {}, data, true)
}

// 静态码对帐
export function abccode (data) {
  return http.httpRequest(https.baseUrl + '/abc/static-pay-list', 'POST', {}, data, true)
}
// 交易明细
export function jmx (data) {
  return http.httpRequest(https.baseUrl + '/miniapp/query-transaction', 'POST', {}, data, true)
}
// 中农对账
export function zntt (params) {
  return http.httpRequest(https.zntt + '/abc/settle', 'GET', params, {}, true)
}
// 验证码
// export function sms1 (params) {
//   return http.httpRequest('/third-auth/abc/sms', 'GET', params, {}, false)
// }
