import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
// 针对导航栏中重复导航报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '物流收款',
      requireAuth: true
    }
  },
  {
    path: '/received',
    name: 'received',
    component: () => import('../views/ReceivedView.vue'),
    meta: {
      title: '收款码',
      requireAuth: true
    }
  },

  {
    path: '/shoukuanma',
    name: 'shoukuanma',
    component: () => import('../views/AllView.vue'),
    meta: {
      title: '收款码',
      requireAuth: true
    }
  },
  {
    path: '/abcduizhang',
    name: 'abcduizhang',
    component: () => import('../views/AbcAccount.vue'),
    meta: {
      title: '电子账户',
      requireAuth: true
    }
  },
  {
    path: '/abcCode',
    name: 'abcCode',
    component: () => import('../views/AbcCode.vue'),
    meta: {
      title: '电子账户静态码',
      requireAuth: true
    }
  },
  {
    path: '/znttSettle',
    name: 'znttSettle',
    component: () => import('../views/znttSettle.vue'),
    meta: {
      title: '中农天泰对账',
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',

    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '商贸物流结算对帐平台-登录'

    }
  }
]

const router = new VueRouter({
  routes
})

export default router
